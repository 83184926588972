<template>
  <b-row v-if="syllabuses.length">
    <b-col cols="12" style="margin-bottom: 1rem;">
      <pcg-btn size="small" @click="deleteSyllabuses()" :disabled="syllabusesToFill === 0">
        <span style="margin-right: .3rem;">{{ $t('components.matrix_syllabuses.delete_all_syllabuses') }}</span>
        <prk-spinner v-if="removeSpinner"></prk-spinner>
      </pcg-btn>
    </b-col>
    <b-col cols="7">
      <prk-table
          :items="syllabuses"
          :excluded-fields="EXCLUDED_FIELDS"
          :busy="$jsonapi.loading"
          hover
          :show-headline="false"
          @row-clicked="rowClicked"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template v-slot:content="{ header, data }">
          <div v-if="header.key === 'actions'" class="d-flex">
            <div style="width: 40px" class="mr-3">
              <pcg-btn round :text="$t('components.matrix_syllabuses.go_to_syllabus')" class="prk-button" :icon="iconEdit"
                       @click="goInto(data.item.id)"/>
            </div>
            <div style="width: 40px">
              <pcg-btn :disabled="data.item.status !== 'Do wypełnienia'"
                       round :icon="iconRemove" text="delete"
                       class="prk-button" variant="danger"
                       @click="deleteSingleSyllabus(data.item.id)"/>
            </div>
          </div>
        </template>
      </prk-table>
    </b-col>
    <b-col cols="5">
      <prk-table
          :items="subjectsInfo"
          v-if="subjectsInfo.length > 0"
          :excluded-fields="EXCLUDED_FIELDS"
          :busy="$jsonapi.loading"
          :show-headline="false">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template v-slot:content="{ header, data }">
          <div style="white-space: pre">{{ data.item[header.key] }}</div>
        </template>
      </prk-table>
    </b-col>
  </b-row>
</template>
<script>

import PrkTable from '../../components/PrkTable'
import PrkSpinner from '../../components/PrkSpinner.vue'
import useSyllabusCard from '../../composables/useSyllabusCard'
import useIcon from '../../composables/useIcon'

export default {
  name: 'matrix-syllabuses',
  setup (_, { root }) {
    const { makeSyllabusCardUniq } = useSyllabusCard(root)
    const { iconEdit, iconRemove } = useIcon(root)
    return {
      makeSyllabusCardUniq,
      iconEdit,
      iconRemove,
    }
  },
  components: { PrkSpinner, PrkTable },
  props: { matrixId: String },
  jsonapi: {
    syllabuses: {
      config: ({ url }) => ({
        method: 'get',
        url: url,
      }),
      variables () {
        return {
          url: this.url,
        }
      },
      update (syllabuses) {
        const syllabusesWithUpdatedName = this.makeSyllabusCardUniq(syllabuses)
        return syllabusesWithUpdatedName.map(syllabus => {
          return {
            id: syllabus.id,
            syllabusCard: syllabus.syllabusCard,
            speciality: syllabus.specialityName || '-',
            specialization: syllabus.specializationName || '-',
            coordinatingPerson: syllabus.coordinators.length
              ? syllabus.coordinators.join('\n') : '-',
            status: this.$t(`general.statuses.${syllabus.status}`),
            subjectsInfo: syllabus.semesters.map(semester => {
              return {
                studyPlanName: syllabus.studyPlanName,
                semester: semester.semesterNumber,
                subjectForm: syllabus.subjectForms.filter(form => {
                  return form.semesterId === semester.id
                }).map(form => form.name).join('\n'),
                kind: syllabus.kind,
              }
            }),
          }
        })
      },
      skip () {
        return !this.matrixId.length
      },
    },
  },
  data () {
    return {
      syllabuses: [],
      subjectsInfo: [],
      url: '',
      removeSpinner: false,
      syllabusesToFill: 0,
    }
  },
  watch: {
    matrixId: {
      handler () {
        this.url = `matrices/${this.matrixId}/matrix_syllabuses`
        this.EXCLUDED_FIELDS = ['id', 'subjectsInfo']
      },
      immediate: true,
    },
    syllabuses: {
      handler () {
        const ids = this.syllabuses.filter(syllabus => syllabus.status === 'Do wypełnienia').map(syllabus => syllabus.id)
        this.syllabusesToFill = ids.length
      },
    },
  },
  methods: {
    rowClicked (syllabus) {
      this.subjectsInfo = syllabus.subjectsInfo
    },
    goInto (id) {
      this.$router.push({ name: 'syllabuses_edit_path', params: { id: id } })
    },
    async deleteSingleSyllabus (id) {
      const res = await this.$removeSwal(1, this.$t('components.matrix_syllabuses.delete_syllabus'))
      if (!res.value) return

      await this.$api.destroy(`syllabuses/${id}`)
      const index = this.syllabuses.findIndex(syllabus => syllabus.id === id)
      this.syllabuses.splice(index, 1)
    },
    async deleteSyllabuses () {
      const ids = this.syllabuses.filter(syllabus => syllabus.status === 'Do wypełnienia').map(syllabus => syllabus.id)
      const res = await this.$removeSwal(ids.length, this.$t('components.matrix_syllabuses.delete_syllabuses'))
      if (!res.value) return
      this.removeSpinner = true
      await this.$api.destroy('syllabuses/destroy_all', {
        params: {
          ids: ids,
        },
      })
      this.removeSpinner = false
      this.$router.go(0)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .pcg-table-narrow-row {
    cursor: pointer !important;
  }
  tr:focus {
    outline: none !important;
  }
}
</style>
