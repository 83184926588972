export const components = {
  confirm_assignment_educators: {
    text: 'The selected settings will overwrite the existing ones. Do you want to save?',
  },
  matrix_syllabuses: {
    delete_all_syllabuses: 'Delete all syllabuses with status \'To be completed\'',
    delete_syllabus: 'Are you sure you want to delete the syllabus?',
    delete_syllabuses: 'Are you sure you want to delete all syllabi with the status \'To be completed\'?',
    go_to_syllabus: 'Transfer to syllabus editing',
  },
  new_changes: {
    text: 'Form changes detected. Do you want to save them?',
  },
  pcg: {
    buttons: {
      add_section: 'Add section',
      copy: 'Copy',
      delete: 'Delete',
      delete_course: 'Delete course',
      delete_matrix: 'Delete matrix',
      delete_matrices: 'Delete matrices',
      delete_plan: 'Delete plan',
      delete_syllabus: 'Delete syllabus',
      delete_template: 'Delete template',
      delete_templates: 'Delete templates',
      download_doc: 'Download DOC file',
      download_pdf: 'Download PDF file',
      edit: 'Edit',
      edit_course: 'Edit course',
      edit_plan: 'Edit plan',
      generated_syllabuses: 'Generated syllabuses',
      pin: 'Pin to',
      save: 'Save',
      show_options: 'Options',
      syllabyus_matrix: 'Matrix',
    },
  },
  prk_beam: {
    approve_and_send: 'Approve and send',
    back_to_edit: 'Back to edit',
    back_to_edit_template: 'Back to edit template',
    preview_template: 'Preview template',
    publish_template: 'Publish template',
    show_to_fill: 'Show to fill',
    show_filled: 'Show filled',
  },
  prk_coordinators_modal: {
    save_mode: 'Save mode',
    save_mode_required: 'To save educators, the save mode must be selected',
  },
  prk_courses: {
    academic_year: 'Academic year',
    back: 'Back to courses',
    characteristics_effects: 'Characteristics effects',
    characteristics_of_the_sciences: 'Characteristics of the sciences',
    clo: 'Course Learning Outcomes (CLO)',
    download: 'Download effects from previous years',
    download_effects: 'Download effects from previous years',
    new_effect: '+ New courses effect',
    reference: 'Reference to general characteristics',
    settings: {
      create: 'Create a standard based on this course',
      download: 'Download the standard',
      download_percentage_share: 'Percentage share',
      download_percentage_share_doc: 'Percentage share DOC',
      download_percentage_share_pdf: 'Percentage share PDF',
      download_printout_coverage_general_effects_by_course_effects: 'Printout of coverage of general effects by course effects',
      download_printout_coverage_general_effects_by_course_effects_doc: 'Printout of coverage of general effects by course effects DOC',
      download_printout_coverage_general_effects_by_course_effects_pdf: 'Printout of coverage of general effects by course effects PDF',
      download_relating_course_effects_to_general_effects: 'Relating course effects to general ones',
      download_relating_course_effects_to_general_effects_doc: 'Relating course effects to general ones DOC',
      download_relating_course_effects_to_general_effects_pdf: 'Relating course effects to general ones PDF',
      download_table_general_effects_for_course: 'Table of general effects for the course',
      download_table_general_effects_for_course_doc: 'Table of general effects for the course DOC',
      download_table_general_effects_for_course_pdf: 'Table of general effects for the course PDF',
      edit: 'Edit details',
    },
    standard: 'Education standard',
    symbol: 'Symbol',
    tooltip: 'New directional effects have been added to the subject',
    update: 'Update fields of study',
    update_info_1: 'There were',
    update_info_2: 'changes in fields of study',
  },
  prk_dictionaries: {
    add_discipline: 'Add discipline',
    assign_learning_outcomes: 'Assign learning outcomes',
    back_to_courses: 'Back to courses',
    back_to_faculties: 'Back to faculties',
    back_to_fields: 'Back do fields',
    characteristic: 'Characteristic',
    course_name: 'Course name',
    create_course: 'Create course',
    create_standard: 'Create standard',
    edit_standard: 'Actualize standard',
    general_effects: 'General effects',
    new_category: '+ New category',
    new_characteristic: '+ New characteristic',
    new_characteristic_effect: '+ New characteristic effect',
    new_discipline: '+ New discipline',
    new_discipline_title: 'New discipline',
    new_field: '+ New field',
    new_subjects_type: '+ New type of subjects',
    return: 'Back to standards',
    save_course: 'Save course',
    standard_name: 'Standard name',
  },
  prk_fields: {
    add_discipline: '+ Add discipline',
    leading: 'Leading discipline',
  },
  prk_matrices: {
    checkbox: 'Add specialty and specialization semesters',
    add_effect: '+ Assign CLO',
    add_study_plan: 'Assign study plan',
    collapse_effect: 'Collapse Directional Effects',
    generate_syllabuses: 'Generate syllabuses',
    new_matrix: 'Create new matrix',
    new_plan: '+ New study plan',
    expand_effect: 'Expand Directional Effects',
    return: 'Return to matrix list',
    semester_type: 'Type of semester',
    set_standard: 'Set as standard',
    settings: {
      clo_effects_matrix: 'CLO effects matrix',
    },
    skip_validation: 'Disable validation of subject effects',
    skip_validation_tooltip: 'Checkbox disables the requirement to use all directional effects assigned to the item in the matrix',
    study_plan: 'Study plans',
    update: 'Regenerate the matrix',
    update_info_2: 'matrix changes',
  },
  prk_syllabus: {
    basic_information: {
      subject_code: 'Subject code (optional)',
      subject_code_class_card: 'Class code (optional)',
      row_2: 'Subject / module name',
      row_2_class_ard: 'Class / module name',
      row_3: 'Name of the unit carrying out the subject',
      row_3_class_card: 'Name of the unit carrying out the class',
      row_4: 'Address of the unit carrying out the subject',
      row_4_class_card: 'Address of the unit carrying out the class',
      row_5: 'Email address of the unit carrying out the subject',
      row_5_class_card: 'Email address of the unit carrying out the class',
      row_6: 'Field of study',
      row_7: 'Level of study',
      row_8: 'Profile',
      row_9: 'Form of study',
      row_10: 'Speciality',
      row_11: 'Academic year and semester(s) of study',
      row_12: 'Subject type',
      row_12_class_card: 'Class type',
      language: 'Language of lecture',
      row_14: 'Coordinator',
      leading_persons: 'Name and surname of the person conducting(s)',
    },
    beam: {
      back_to_edit: 'Return to editing the template',
      create_course: 'Create field',
      publish: 'Publish',
      preview: 'Template preview',
      save: 'Save',
      selected_elements: 'Selected elements',
    },
    buttons: {
      add_new_table_row: 'Add a new table row',
      add_element: 'Add element',
      add_new_element: 'Add new element',
      comments: 'Comments',
      coordinators: 'Coordinators',
      delete: 'Delete',
      delete_course: 'Delete course',
      delete_discipline: 'Delete discipline',
      delete_effect: 'Delete effect',
      delete_elements: 'Delete element | Delete elements',
      delete_plan: 'Delete plan',
      delete_row: 'Delete row',
      delete_rows: 'Delete rows',
      delete_syllabus: 'Delete syllabus',
      download_doc: 'Download DOC file',
      download_pdf: 'Download PDF file',
      download_multi_pdf: 'Download the syllabuses combined into one PDF file',
      download_excel: 'Download Excel file',
      edit: 'Edit',
      edit_effect: 'Edit effect',
      edit_plan: 'Edit plan',
      edit_row: 'Edit row',
      edit_syllabus: 'Edit syllabus',
      generated_syllabuses: 'Generated syllabuses',
      new_subject_effect: 'New subject effect',
      print_syllabus: 'Print syllabus',
      send_email: 'Send email',
      send_syllabus: 'Send syllabus',
      show_options: 'Options',
      syllabus_matrix: 'Matrix',
      un_select_elements: 'Unselect elements',
    },
    errors: {
      cannot_delete: 'Directional effects assigned to approved syllabuses cannot be removed'
    },
    header: {
      academic_year: 'Academic year',
      check_education_effects: 'Check education effects',
      class_card: 'Class card',
      copy_from_previous_years: 'Copy from previous years',
      during_education: 'Training data',
      language: 'Syllabus language',
      info_box: 'Template',
      logo: 'UNIVERSITY LOGOTYPE',
      title: 'SYLLABUS',
      training_cycle: 'Training cycle',
      to_fill: 'To fill',
    },
    list: {
      checkbox_header: 'Template name',
    },
    literature: {
      basic: 'Basic literature',
      supplementary: 'Supllemntary literature',
    },
    modal: {
      literature: {
        add_literature: 'Add literature',
        author: 'Author',
        title: 'Title',
        publishing_house: 'Publising House',
        place_of_publication: 'Place of publication',
        year: 'Year',
      },
    },
    new_element: {
      checkbox: {
        title: 'Checkbox',
        text_above: 'Description above the checkbox (optional)',
        name: 'Checkbox name',
        text_below: 'Description below the checkbox (optional)',
      },
      one_line: {
        title: 'One line of text',
        text_above: 'Description above the one line of text (optional)',
        name: 'One line of text name',
        text_below: 'Description below the one line of text (optional)',
      },
      table: {
        title: 'Table',
        text_above: 'Description above the table (optional)',
        columns_number: 'Number of columns',
        heading: 'Column header',
        text_below: 'Description below the table (optional)',
      },
      text_field: {
        title: 'Text field',
        text_above: 'Description above the text field (optional)',
        name: 'Text field name',
        text_below: 'Description below the text field (optional)',
      },
    },
    section: {
      additional_heading: 'Additional section ',
      automatic_download: 'Automatic download',
      choose_element: 'Select the items to be included in this section',
      description: 'Add a description under the section',
      fill_user: 'To be completed by the teacher',
      heading: 'Heading',
      new_section: '+ New section',
    },
    sections: {
      number_1_0: 'Basic information',
      number_1_1: 'Forms of classes, number of hours and ECTS points',
      number_1_2: 'Way of carrying out the classes',
      number_1_3: 'The method of passing the subjects',
      number_2: 'Prerequisites',
      number_3: 'Objectives, learning outcomes, program content and didactic methods used',
      number_3_1: 'Objectives of the subject / module',
      number_3_2: 'The effect of education for the subject / module',
      number_3_3: 'Subject content',
      number_3_4: 'Didactic methods',
      number_4: 'Assessment methods and criteria',
      number_4_1: 'Ways to verify learning outcomes',
      number_4_2: 'Credit conditions for the course',
      number_5: 'The total student workload',
      number_6: 'Professional training within the subject',
      number_7: 'Literature',
      x: 'Name your section',
    },
    setting: {
      add_description: 'Add a description under the section',
      hide_printout: 'Hide on the printout',
      remove_description: 'Remove a description under the section',
      restore: 'Reset the default name',
      show_printout: 'Show on the printout',
    },
    return: {
      general_effects: 'Return to general effects',
      matrices_list: 'Return to matrices list',
      plans_list: 'Return to Plans List',
      template_list: 'Return to template list',
    },
    table: {
      author: 'Author',
      course_code: 'Subject course',
      course_name: 'Course',
      academic_year: 'Academic year',
      category: 'Category',
      characteristic: 'Characteristic | Characteristics',
      characteristics: 'Characteristics',
      characteristics_gs: 'Charact.General/Standard',
      characteristic_name: 'Name',
      coordinator: 'Coordinator',
      en_description: 'Description (ang)',
      value_0: 'Header 1',
      value_1: 'Header 2',
      value_2: 'Header 3',
      course: 'Course',
      code: 'Subject code',
      coordinating_person: 'Coordinator',
      course_code_info: 'As agreed at the faculty',
      course_effects: 'CLO',
      department: 'Department',
      description: 'Description',
      directional_learning_outcomes: 'Effects of course',
      discipline: 'Discipline',
      ects: 'ECTS',
      educational_outcome: 'Effect symbol',
      effect_content: 'Content of the effect',
      effect_content_obj: 'Effect content for a subject (module)',
      effect_content_obj_class_card: 'Effect content for a class (module)',
      effect_description: 'Effect description',
      faculty: 'Faculty',
      field: 'Field',
      field_exercises: 'Field exercises',
      first_name: 'First name',
      form: 'Form',
      form_of_class: 'Form of classes',
      form_of_credit: 'Form of credit',
      form_of_classes: 'Forms of classes',
      form_of_credits: 'Forms of credit',
      form_of_credits2: 'Rigors',
      form_of_passing_the_subject: 'Form of getting credit for the subject',
      from_plan: 'From plan',
      general_effects_reference: 'General effects reference',
      header: 'Header',
      keks: 'Reference to standard / directional learning outcomes',
      kens: 'Reference to teaching effects',
      keu_year: 'KEU year',
      kind: 'Kind',
      laboratory_exercises: 'Laboratory exercises',
      last_name: 'Last name',
      leading_discipline: 'Leading discipline',
      learning_verification: 'Ways to verify learning outcomes',
      lectures: 'Lectures',
      lecturers: 'Lecturers',
      lp: 'Lp',
      percent: 'Percent',
      place_of_publication: 'Place of publication',
      publishing_house: 'Publishing house',
      level: 'Level',
      methods_of_assessment_of_learning_outcomes: 'Methods for assessing learning outcomes',
      name: 'Name',
      name_tok_subject: 'Name Tok/Subject',
      name_of_subject: 'Name of subject',
      name_of_term: 'Name of term',
      number_of_hours: 'Number of hours',
      profile: 'Profile',
      range: 'Range',
      responsible_person: 'Coordinator',
      responsible_persons: 'Coordinator',
      roles: 'Roles',
      semester: 'Semester',
      semester_number: 'Semester number',
      shortcut: 'Shortcut',
      shortcut_subject_effect: 'Shortcut SE',
      specialization: 'Specialization',
      specialization_name: 'Specialization',
      speciality: 'Specialty',
      speciality_name: 'Specialty',
      status: 'Status | Statuses',
      study_plan_name: 'Name of Course',
      study_profile: 'Profile',
      subject: 'Subject',
      subject_form: 'Subject form',
      subjects: 'Subjects',
      syllabus_card: 'Card/Syllabus',
      syllabuses_count: 'Used in syllabuses',
      symbol: 'Symbol',
      term: 'Term',
      type: 'Type',
      title: 'Title',
      type_of_term: 'Type of term',
      valid_date: 'Valid date',
      verification_method: 'A method of verifying the learning effect',
      year: 'Year',
      year_and_term: 'Year and term',
    },
    tooltip: {
      heading: 'Default name: ',
      hide: 'Hide section',
      info: 'As agreed at the faculty',
      options: 'Options',
      remove: 'Remove section',
      show_training_cycle: 'Show training cycle',
    },
    workload: {
      contact_hours: 'Contact hours resulting from the study plan',
      ects: 'SUMMARY NUMBER OF ECTS POINTS',
      header_1: 'Hours',
      header_2: 'The average number of hours to complete the activity',
      no_contact_hours: 'Non-contact hours - student\'s own work (preparation for classes, examinations, etc.)',
      other: 'Others with the participation of a teacher (participation in consultations, exam)',
      sum: 'SUM OF HOURS',
    },
  },

  pcg_header_messages: {
    empty_messages: 'You don\'t have any messages',
    footer_see_more: 'See more >',
    header_title: 'Messages',
  },
  prk_header_navbar: {
    login_btn: 'Sign In',
  },
  pcg_header_notifications: {
    company_message: '{userName} awaiting company approval',
    company_title: 'New employer',
    dictionary_message: 'A new dictionaries has been added - {title}',
    dictionary_title: 'New dictionaries',
    dictionary_word_message: 'User {userName} suggested a new term {title}',
    dictionary_word_title: 'New term suggestion',
    empty_notifications: 'You have no notifications',
    event_message: '{userName} created a new event {title}.',
    event_title: 'New event',
    header_title: 'Notifications',
    offer_application_message: '{userName} submitted an application for offer {title}.',
    offer_application_title: 'Application for an offer',
    offer_invitation_message: '{userName} invites you to apply for the offer {title}.',
    offer_invitation_title: 'Invitation to an offer',
    offer_message: '{userName} created a new job offer for {title}.',
    offer_title: 'New offer',
  },
  pcg_header_profile_button: {
    lang_change_to: 'Change language to {lang}',
    logout: 'Log out',
    role_change_to: 'Change role to {role}',
    settings: 'Settings',
  },
  pcg_pagination_summary: {
    summary: 'Showing {from} - {to} of {total} results',
  },
  pcg_search_select: {
    add_value: 'Add value',
    change: 'Change',
  },
  prk_modals: {
    add_course: {
      name: 'Add course',
      create_course: 'Create course',
      // TODO powtórzenie w add_matrix
      form: 'Form (Optional)',
      level: 'Level (optional)',
      year: 'Academic Year (optional)',
      class: 'class',
    },
    add_discipline: {
      course: 'Course of study',
      title: 'Add discipline',
      valid_date: 'The date is valid',
    },
    add_effect: {
      add_characteristic: 'Add a characteristic effect',
      add_teacher: 'Add a teaching effect',
      category: 'Category',
      characteristic: 'New characteristic effect',
      description: 'Description of the Effect',
      new_characteristic_effect: '+ New characteristic effect',
      new_characteristic: 'New characteristic',
      range: 'Range',
      symbol: 'Symbol',
      teacher: 'New teaching effect',
    },
    add_matrix: {
      add: 'Create a new matrix',
      confirm: 'Create a new matrix?',
      create_matrix: 'Create a matrix',
      form: 'Form',
      level: 'Level',
      year: 'Academic Year',
      class: 'Class',
      specialty: 'Specialty (Optional)',
    },
    add_subject: {
      edit: 'Edit subject',
      forms_of_classes: 'Form of classes',
      form_of_credit: 'Form of credit',
      name: 'Add new subject',
      new_form_of_classes: 'New form of classes',
    },
    colors: {
      color: 'Color',
      name: 'Name',
      shortcut: 'Shortcut',
      level: 'Level',
      profile: 'Profile',
      select_color: 'Select color',
      select: 'Select',
      add_characteristic: 'Add characteristic',
    },
    confirm_copying_syllabus: {
      text: 'The syllabus will be overwritten. Are you sure you want to copy?',
    },
    confirm_deletion_syllabuses: {
      text: 'Some syllabuses have been completed. Are you sure you want to delete?',
    },
    confirm_plan_updates: {
      text: 'The following subjects have been assigned to the matrix. ' +
        'Their names will not be updated. Are you sure you want to continue?',
    },
    coordinators: {
      coordinating_person: 'Coordinator',
      name: 'Coordinators',
      save: 'Save',
      supervisor: 'Supervisior',
    },
    courses_effect: {
      title: 'Standard effects',
    },
    comments: {
      name: 'Comments',
      add_comment: 'Add comment',
    },
    directional: {
      add_field: 'Add field',
      new_field: 'New field',
      add_discipline: 'Add discipline',
    },
    course_effect: {
      must_select_category: 'You must select a category',
      must_select_effect: 'You must select at least one standard effect',
      name: 'Add a directional effect',
      reference_effects: 'Reference to general characteristics',
      save: 'Confirm and add an effect',
      symbol: 'Directional effect symbol',
    },
    course_effects: {
      name: 'Add course effects',
      raw_text: 'Course effects',
      select_all: 'Select the course effects you want to add',
    },
    effects_list: {
      close: 'Close',
    },
    effects_picker: {
      btn_title: 'Choose effects',
      description: 'Choose which year you want to download effects',
      name: 'Download effects from previous years',
      select_description: 'Academic year',
    },
    file_type: {
      title: 'Choose the file type',
      warning: 'You need to select a file type',
    },
    plans: {
      import_all: 'Import all',
      name: 'Import plan',
      save: 'Import',
    },
    send_email: {
      applies: 'Applies to the syllabus | Applies to the syllabuses',
      cancel: 'Cancel',
      email_content: 'Email content',
      message_error: 'Content is required',
      name: 'Send email',
      recipients: 'Recipients',
      recipient_error: 'Recipient is required',
      send: 'Send',
      title: 'Title',
      title_error: 'Title is required',
    },
    standard_picker: {
      description: 'Select the standard you want to assign to this direction',
      name: 'Download standard',
      name_of_standard: 'Name of standard',
    },
    subject_effect: {
      category: 'Category',
      confirm: 'Confirm and add effect',
      description: 'Description of the subject effect',
      tf: 'Reference to Teacher Effects',
      dlo: 'Reference to standard / directional learning outcomes',
      name: 'Add subject effect',
      select_dlo: 'Choose Learning Outcomes',
      select_tf: 'Select Teacher Effects',
    },
  },
  view_missing_effects_modal: {
    button: 'Close',
    description: 'Description',
    symbol: 'Symbol',
    title: 'Unassigned directional effects',
  },
}
